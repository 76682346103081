import { Link } from 'react-router-dom';
import './BackLink.scss';

interface BackLinkProps {
    href?: string;
    onClick?: () => void;
    label?: string;
}

const BackLink = (props: BackLinkProps) => {
    return (
        <p className="back-link">
            {props.onClick
                ? <a href="#" onClick={props.onClick}>&laquo; {props.label ?? 'Home'}</a>
                : <Link to={props.href ?? "/home"} onClick={props.onClick}>&laquo; {props.label ?? 'Home'}</Link>
            }
        </p>
    );
}

export default BackLink;