import './AccountPage.scss';
import useClaims from '../../../hooks/useClaims';
import WideButton from '../../atoms/WideButton/WideButton';
import { useContext, useEffect } from 'react';
import GlobalVariablesContext from '../../../services/GlobalVariablesContext';

const AccountPage = () => {

    const claims = useClaims();

    const { setActiveGroup } = useContext(GlobalVariablesContext);

    useEffect(() => {
        setActiveGroup(null);
    }, []);
    
    return (
        <div id="main-content" className="account-page-container">
            {claims && (
                <>
                    <h1>Account</h1>
                    <h2>{claims.given_name + ' ' + claims.family_name}</h2>
                    <ul>
                        <li>{claims.emails[0]}</li>
                    </ul>
                    <WideButton label='Edit profile' navigatesTo='/edit-profile' />
                    <WideButton label='Reset password' navigatesTo='/edit-password' />
                    <WideButton label='Log out' navigatesTo='/logout' />
                </>
            )
            }
        </div >
    );
}

export default AccountPage;