import { useEffect } from 'react';
import LinkBox from '../../atoms/LinkBox/LinkBox';
import chatService from '../../../services/chatService';

const LogoutPage = () => {

    useEffect(() => {
        chatService.logout();
    })

    return (
        <>
            <h2>Logged Out</h2>
            <p>You are now signed out.</p>
            <LinkBox href="/" label="Back to home page" />
        </>
    );
}

export default LogoutPage;