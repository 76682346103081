import './ChatMessages.scss';
import { useEffect, useRef } from "react";
import { IChatMessage } from "../../../services/chatService";
import ChatMessage from "../ChatMessage/ChatMessage";

interface IChatMessagesProps {
    messages: IChatMessage[]
}

const ChatMessages = (props: IChatMessagesProps) => {

    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current && containerRef.current.scrollTo) {
            containerRef.current.scrollTo(0, containerRef.current.scrollHeight);
        }
    }, [props.messages]);

    return (
        <div className="chat-messages" ref={containerRef}>
            {props.messages.map(m => (
                <ChatMessage key={m.id} message={m} />
            ))}
        </div>
    );
}

export default ChatMessages;