import './AppBox.scss';
import { Link } from "react-router-dom";
import ProfileIcon from "../../atoms/Icons/ProfileIcon";
import FriendsIcon from "../../atoms/Icons/FriendsIcon";
import NewChatIcon from "../../atoms/Icons/NewChatIcon";

interface AppBoxProps {
    userFullName: string;
}

const AppBox = (props: AppBoxProps) => {

    return (
        <div id="menu">
            <Link to='/manage-account'>
                <span id="menu-burger">
                    <ProfileIcon width={'20pt'} height={'20pt'} />
                </span>
            </Link>
            <Link to='/friends'>
                <span id="menu-friends">
                    <FriendsIcon width={'20pt'} height={'20pt'} />
                </span>
            </Link>
            <Link to='/new-group'>
                <span id="menu-new-chat">
                    <NewChatIcon width={'20pt'} height={'20pt'} />
                </span>
            </Link>
            <Link to='/groups' id="menu-change-groups">
                Groups &raquo;
            </Link>
            <span id="menu-title">
                {props.userFullName}
            </span>
        </div>
    );

}

export default AppBox;