import { useState } from 'react';

const useClaims = (): any => {

    const [userJson] = useState(localStorage.getItem('mousechat.user'));

    if (userJson === null) {
        return null;
    }

    return JSON.parse(userJson);
}

export default useClaims;