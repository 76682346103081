import { useParams } from 'react-router-dom';
import './GroupPage.scss';
import { useContext, useEffect, useState } from 'react';
import chatService, { IChatMessage } from '../../../services/chatService';
import Spinner from '../../atoms/Spinner/Spinner';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import ChatUserTags from '../../organisms/ChatUserTags/ChatUserTags';
import ChatMessages from '../../organisms/ChatMessages/ChatMessages';
import ChatStagingPanel from '../../organisms/ChatStagingPanel/ChatStagingPanel';
import GlobalVariablesContext from '../../../services/GlobalVariablesContext';

const GroupPage = () => {

    if ('Notification' in window && Notification.permission !== 'denied') {
        Notification.requestPermission();
    }

    const { groupName } = useParams();

    const { activeGroup, setActiveGroup, activeGroupMessages, setActiveGroupMessages } = useContext(GlobalVariablesContext);

    const [loadingGroup, setLoadingGroup] = useState(false);

    const [loadingGroupError, setLoadingGroupError] = useState('');

    const [sendMessageError, setSendMessageError] = useState('');

    useEffect(() => {
        setLoadingGroup(true);
        setLoadingGroupError('');
        setActiveGroup(null);
        setActiveGroupMessages([]);
        chatService
            .getGroup(groupName)
            .then(data => {
                setLoadingGroup(false);
                setActiveGroup(data.data);
            })
            .catch(error => {
                setLoadingGroup(false);
                setLoadingGroupError(error.response?.data?.error ?? 'Error occurred.');
            });
    }, [groupName]);

    useEffect(() => {
        if (activeGroup) {
            setActiveGroupMessages([...activeGroup.messages]);
        } else {
            setActiveGroupMessages([]);
        }
    }, [activeGroup]);

    const handleMessageSend = (message: string) => {
        setSendMessageError(null);
        chatService
            .createMessage({
                groupName: groupName,
                message: message
            })
            .then(data => {

                let replacementMessages = [...activeGroupMessages, {
                    id: data.data.id,
                    userName: data.data.userName,
                    fullName: data.data.fullName,
                    message: message,
                    authoredDate: new Date().toISOString()
                } as IChatMessage];
                setActiveGroupMessages(replacementMessages);

                new Audio(process.env.PUBLIC_URL + '/notification.mp3').play();

            })
            .catch(error => {
                setSendMessageError(error.response?.data?.error ?? 'Error occurred.');
            });
    };

    return (
        <div id="main-content" className="group-page-container">
            {loadingGroup && <Spinner />}
            {loadingGroupError && <ErrorAlert title='Cannot load messages' message={loadingGroupError} />}
            {activeGroup && (
                <>
                    <h1 className="chat-title">
                        {activeGroup.title}
                    </h1>
                    <ChatUserTags users={activeGroup.users} />
                    <ChatMessages messages={activeGroupMessages} />
                    <ChatStagingPanel onSend={m => handleMessageSend(m)} />
                </>
            )}
        </div>
    );

}

export default GroupPage;