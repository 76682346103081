import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignedInPage from './components/pages/SignedInPage/SignedInPage';
import FriendSearchPage from './components/pages/FriendSearchPage/FriendSearchPage';
import CreateGroupPage from './components/pages/CreateGroupPage/CreateGroupPage';
import LogoutPage from './components/pages/LogoutPage/LogoutPage';
import EditPasswordPage from './components/pages/EditPasswordPage/EditPasswordPage';
import EditProfilePage from './components/pages/EditProfilePage/EditProfilePage';
import FriendsPage from './components/pages/FriendsPage/FriendsPage';
import AccountPage from './components/pages/AccountPage/AccountPage';
import Layout from './components/templates/Layout';
import HomePage from './components/pages/HomePage/HomePage';
import GroupsPage from './components/pages/GroupsPage/GroupsPage';
import GroupPage from './components/pages/GroupPage/GroupPage';
import GlobalVariablesContextProvider from './services/GlobalVariablesContextProvider';

const App = () => {
  return (
    <GlobalVariablesContextProvider>
      <BrowserRouter basename="/mousechat">
        <Routes>
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/signed-in" element={<SignedInPage />} />
          <Route path="/edit-password" element={<EditPasswordPage />} />
          <Route path="/edit-profile" element={<EditProfilePage />} />
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/groups" element={<GroupsPage />} />
            <Route path="/groups/:groupName" element={<GroupPage />} />
            <Route path="/friends" element={<FriendsPage />} />
            <Route path="/friend-search" element={<FriendSearchPage />} />
            <Route path="/new-group" element={<CreateGroupPage />} />
            <Route path="/manage-account" element={<AccountPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </GlobalVariablesContextProvider>
  );
}

export default App;
