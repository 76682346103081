import ToggleSwitch from '../../atoms/ToggleSwitch/ToggleSwitch';
import './FriendToggle.scss';

interface FriendToggleProps {
    id: number;
    userName: string;
    fullName: string;
    checked: boolean;
    onChange: (value: boolean) => void;
}

const FriendToggle = (props: FriendToggleProps) => {
    return (
        <div className="friend-toggle">
            <span className="friend-toggle-name">{props.fullName}</span>
            <div className="friend-toggle-switch">
                <ToggleSwitch checked={props.checked} onChange={checked => props.onChange(checked)} />
            </div>
            <div className="friend-toggle-done"></div>
        </div>
    );
}

export default FriendToggle;