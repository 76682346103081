import { useContext, useEffect, useState } from "react";
import config from "../../../config.json";
import { IChatMessage } from "../../../services/chatService";
import GlobalVariablesContext from "../../../services/GlobalVariablesContext";

export interface INotificationData {
    type?: undefined | 'groupListChanged';
}

export interface IChatNotificationData extends INotificationData {
    senderUserName: string;
    senderFullName: string;
    groupName: string;
    groupTitle: string;
    messageDate: string;
    messageId: number;
    messageContent: string;
}

interface WebSocketNotificationHandlerProps {
    onReceiveMessage: (groupName: string, groupTitle: string, newMessage: IChatMessage) => void;
    onGroupsChanged: () => void;
}

const WebSocketNotificationHandler = (props: WebSocketNotificationHandlerProps) => {

    let { setWebSocketConnected } = useContext(GlobalVariablesContext);

    let [tryToConnect, setTryToConnect] = useState(true);

    useEffect(() => {
        let bearerToken = localStorage.getItem('mousechat.bearerToken');
        if (tryToConnect && bearerToken && !client) {
            try {
                client = new WebSocket(config.webSocketAddress, config.webSocketProtocol);
                client.onopen = () => {
                    client.send(`BEARER_TOKEN ${bearerToken}`);
                    console.info(`Connected to WSNotify server: ${config.webSocketAddress}`);
                    setWebSocketConnected(true);
                    setTryToConnect(false);
                };
                client.onclose = () => {
                    console.error('Disconnected from WSNotify server.');
                    setWebSocketConnected(false);
                    tryAgainSoon();
                }
                client.onmessage = (ev: MessageEvent<string>) => {
                    handleWebSocketMessage(ev);
                }
            } catch {
                tryAgainSoon();
            }
        }
    }, [tryToConnect]);

    const tryAgainSoon = () => {
        setTryToConnect(false);
        setTimeout(() => {
            client = null;
            setTryToConnect(true);
        }, 30000);
    }

    const handleWebSocketMessage = (ev: MessageEvent<string>) => {
        let spaceIndex = ev.data.indexOf(' ');
        let command = ev.data.substring(0, spaceIndex);
        let data = ev.data.substring(spaceIndex + 1);
        switch (command) {
            case 'NOTIFICATION':
                console.log(data);
                let notificationData = JSON.parse(data) as INotificationData;
                switch (notificationData.type) {
                    case 'groupListChanged':
                        handleGroupsChangedNotification(notificationData);
                        break;

                    default:
                        handleChatNotification(notificationData as IChatNotificationData);
                        break;
                }
                break;
        }
    }

    const handleGroupsChangedNotification = (notificationData: INotificationData) => {
        props.onGroupsChanged();
    }

    const handleChatNotification = (notificationData: IChatNotificationData) => {
        let newMessage: IChatMessage = {
            id: notificationData.messageId,
            userName: notificationData.senderUserName,
            fullName: notificationData.senderFullName,
            authoredDate: notificationData.messageDate,
            message: notificationData.messageContent,
        };
        props.onReceiveMessage(notificationData.groupName, notificationData.groupTitle, newMessage);
    }

    return (
        <></>
    );

}

export default WebSocketNotificationHandler;

export let client: WebSocket;