import config from "../../../config.json";
import { useEffect } from 'react';

const EditPasswordPage = () => {

    useEffect(() => {
        window.location.href = config.b2cResetPasswordUrl;
    }, []);

    return <></>;
}

export default EditPasswordPage;