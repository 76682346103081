import React, { useState } from 'react';
import ActionButton from '../../atoms/ActionButton/ActionButton';
import "./ConfirmDialog.scss";

interface ConfirmDialogProps {
    title: string;
    message: string;
    open: boolean;
    onYesClick?: () => void;
    onNoClick?: () => void;
}

const ConfirmDialog = (props: ConfirmDialogProps) => {

    const [open, setOpen] = useState(props.open);

    const handleYesClicked = () => {
        setOpen(false);
        props.onYesClick?.();
    }

    const handleNoClicked = () => {
        setOpen(false);
        props.onNoClick?.();
    }

    return (
        <dialog className="confirm-dialog" open={open}>
            <h2 className="confirm-dialog-title">{props.title}</h2>
            <p className="confirm-dialog-message">{props.message}</p>
            <ActionButton label='Yes' onClick={() => handleYesClicked()} />
            <ActionButton label='No' onClick={() => handleNoClicked()} />
        </dialog>
    );
};

export default ConfirmDialog;

