import { useNavigate } from 'react-router-dom';
import './WideButton.scss';

interface WideButtonProps {
    label: string;
    variant?: 'action' | 'alt-action' | 'warning';
    submits?: boolean;
    enabled?: boolean;
    onClick?: () => void;
    navigatesTo?: string;
}

const WideButton = (props: WideButtonProps) => {

    let navigate = useNavigate();

    const onClick = props.onClick ?? (props.navigatesTo ? () => {
        navigate(props.navigatesTo ?? "/");
    } : undefined);

    return (
        <button
            className={"wide-button wide-button-" + (props.variant ?? "action")}
            type={(props.submits ?? false) ? "submit" : "button"}
            onClick={onClick} onSubmit={onClick} disabled={!(props.enabled ?? true)}
        >
            {props.label}
        </button>
    );
}

export default WideButton;