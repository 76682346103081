import './ChatStagingPanel.scss';
import { useState } from "react";

interface IChatStagingPanelProps {
    onSend: (message: string) => void;
}

const ChatStagingPanel = (props: IChatStagingPanelProps) => {

    const [message, setMessage] = useState('');

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSend(message);
        setMessage('');
    };

    const handleTextAreaKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.which === 13 && !e.shiftKey) {
            e.preventDefault();
            (document.getElementById('chat-send-button') as HTMLButtonElement).click();
        }
    };

    return (
        <div id="chat-staging-panel">
            <form id="chat-form" onSubmit={e => handleFormSubmit(e)}>
                <textarea placeholder='Enter your message here' onKeyDown={e => handleTextAreaKeyDown(e)} onChange={e => setMessage(e.target.value)} value={message}></textarea>
                <button id="chat-send-button" type="submit">Send</button>
            </form>
        </div>
    );
}

export default ChatStagingPanel;