import { IChatGroupSummaryInfo } from '../../../services/chatService';
import WideButton from '../../atoms/WideButton/WideButton';
import './HomePage.scss';

interface IHomePageProps {
    groups?: IChatGroupSummaryInfo[];
}

const HomePage = (props: IHomePageProps) => {
    return (
        <div id="main-content" className="home-page-container">
            <div className="home-page-box">
                <p>No chat selected.</p>
                <WideButton label='Create a group' navigatesTo='/new-group' variant='action' />
                <WideButton label='Manage your friends' navigatesTo='/friends' />
            </div>
        </div>
    );
}

export default HomePage;