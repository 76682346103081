import { useContext, useEffect, useState } from 'react';
import chatService, { IChatGroupSummaryInfo, IChatMessage } from '../../services/chatService';
import './Layout.scss';
import { Outlet, useNavigate } from "react-router-dom";
import useClaims from '../../hooks/useClaims';
import Spinner from '../atoms/Spinner/Spinner';
import ErrorAlert from '../atoms/ErrorAlert/ErrorAlert';
import AppBox from '../organisms/AppBox/AppBox';
import GroupsList from '../organisms/GroupsList/GroupsList';
import WebSocketNotificationHandler from '../organisms/WebSocketNotificationHandler/WebSocketNotificationHandler';
import { GlobalVariablesContext } from '../../services/GlobalVariablesContext';
import StatusBar from '../organisms/StatusBar/StatusBar';

const Layout = () => {

    const claims = useClaims();

    let navigate = useNavigate();

    const { allGroups, setAllGroups, activeGroup, activeGroupMessages, setActiveGroupMessages } = useContext(GlobalVariablesContext);

    const [loadingGroups, setLoadingGroups] = useState(true);

    const [groupsError, setGroupsError] = useState('');

    const [newMessage, setNewMessage] = useState<{ groupName: string, message: IChatMessage }>(null);

    const loadGroups = async () => {
        setLoadingGroups(true);
        setAllGroups(null);
        await chatService
            .getGroups()
            .then(data => {
                setLoadingGroups(false);
                setGroupsError('');
                setAllGroups(data.data);
            })
            .catch(error => {
                setLoadingGroups(false);
                setGroupsError(error.response?.data?.error ?? 'Error occurred.');
            });
    }

    useEffect(() => {
        loadGroups();
    }, []);

    useEffect(() => {
        if (newMessage && activeGroup) {
            if (activeGroup.name === newMessage.groupName) {
                let replacementMessages = [...activeGroupMessages];
                if (!replacementMessages.find(m => m.id === newMessage.message.id)) {
                    replacementMessages.push(newMessage.message);
                }
                setActiveGroupMessages(replacementMessages);
            }
        }

        if (newMessage && allGroups) {
            let otherGroup = allGroups.find(g => g.name === newMessage.groupName && g !== activeGroup);
            if (otherGroup) {
                otherGroup.lastMessageAuthor = newMessage.message.fullName;
                otherGroup.lastMessageDate = newMessage.message.authoredDate;
                otherGroup.lastMessageContent = newMessage.message.message;
                otherGroup.unreadCount++;
                const newAllGroups = [...allGroups];
                setAllGroups(newAllGroups);
            }
        }

        setNewMessage(null);
    }, [allGroups, activeGroup, newMessage]);

    const handleGroupClick = (group: IChatGroupSummaryInfo) => {
        navigate('/groups/' + group.name);
    };

    const handleNotificationClick = (groupName: string) => {
        navigate(`/groups/${groupName}`);
    }

    const handleNewIncomingMessage = (groupName: string, groupTitle: string, newMessage: IChatMessage) => {

        // Play sound
        new Audio(process.env.PUBLIC_URL + '/notification.mp3').play();

        // Show browser notification
        if ('Notification' in window && Notification.permission === 'granted') {
            let notification = new Notification(groupTitle, {
                icon: process.env.PUBLIC_URL + '/logo.png',
                tag: 'chat',
                body: `${newMessage.fullName}: ${newMessage.message}`,
            });
            notification.onclick = () => handleNotificationClick(groupName);
        }

        setNewMessage({
            groupName: groupName,
            message: newMessage
        });
    };

    const handleGroupsChangedNotification = () => {
        loadGroups();
    };

    return (
        <>
            {loadingGroups && <Spinner />}
            {groupsError && <ErrorAlert title='Cannot load groups' message={groupsError} />}
            {!groupsError && (
                <>
                    <AppBox userFullName={claims ? claims.given_name + ' ' + claims.family_name : ''} />
                    <GroupsList groups={allGroups} onGroupClick={g => handleGroupClick(g)} />
                    <StatusBar />
                    {!loadingGroups && <Outlet />}
                    <WebSocketNotificationHandler
                        onReceiveMessage={(groupName, groupTitle, message) => handleNewIncomingMessage(groupName, groupTitle, message)}
                        onGroupsChanged={() => handleGroupsChangedNotification()}
                    />
                </>
            )}
        </>
    );
};

export default Layout;
