import { Dispatch, SetStateAction, createContext } from "react";
import { IChatGroupInfo, IChatGroupSummaryInfo, IChatMessage } from "./chatService";

export interface IGlobalVariablesContextData {
    allGroups: IChatGroupSummaryInfo[];
    setAllGroups: Dispatch<SetStateAction<IChatGroupSummaryInfo[]>>;
    activeGroup: IChatGroupInfo;
    setActiveGroup: Dispatch<SetStateAction<IChatGroupInfo>>;
    activeGroupMessages: IChatMessage[];
    setActiveGroupMessages: Dispatch<SetStateAction<IChatMessage[]>>;
    webSocketConnected: boolean;
    setWebSocketConnected: Dispatch<SetStateAction<boolean>>;
};

export const GlobalVariablesContext = createContext<IGlobalVariablesContextData>({
    allGroups: [],
    setAllGroups: null,
    activeGroup: null,
    setActiveGroup: null,
    activeGroupMessages: [],
    setActiveGroupMessages: null,
    webSocketConnected: false,
    setWebSocketConnected: null
});

export default GlobalVariablesContext;