import "./TextBox.scss";

interface TextBoxProps {
    name: string;
    type?: string;
    placeholder?: string;
    required: boolean;
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const TextBox = (props: TextBoxProps) => {
    return (
        <input className="textbox" type={props.type ?? 'text'} name={props.name} placeholder={props.placeholder} required={props.required} value={props.value} onChange={props.onChange} />
    );
}

export default TextBox;