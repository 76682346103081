import { ReactNode, useMemo, useState } from "react";
import { IChatGroupInfo, IChatGroupSummaryInfo, IChatMessage } from "./chatService";
import GlobalVariablesContext from "./GlobalVariablesContext";

export interface IGlobalVariablesContextProviderProps {
    children: ReactNode;
}

export const GlobalVariablesContextProvider = (props: IGlobalVariablesContextProviderProps) => {

    const [allGroups, setAllGroups] = useState<IChatGroupSummaryInfo[]>(null);

    const [activeGroup, setActiveGroup] = useState<IChatGroupInfo>(null);

    const [activeGroupMessages, setActiveGroupMessages] = useState<IChatMessage[]>([]);

    const [webSocketConnected, setWebSocketConnected] = useState<boolean>(false);

    const contextValue = useMemo(() => (
        { allGroups, setAllGroups, activeGroup, setActiveGroup, activeGroupMessages, setActiveGroupMessages, webSocketConnected, setWebSocketConnected }
    ), [allGroups, activeGroup, activeGroupMessages, webSocketConnected]);

    return <GlobalVariablesContext.Provider value={contextValue}>
        {props.children}
    </GlobalVariablesContext.Provider>;
};

export default GlobalVariablesContextProvider;