import { useNavigate } from 'react-router-dom';
import './ActionButton.scss';

interface ActionButtonProps {
    label: string;
    submits?: boolean;
    enabled?: boolean;
    onClick?: () => void;
    navigatesTo?: string;
}

const ActionButton = (props: ActionButtonProps) => {

    let navigate = useNavigate();

    const onClick = props.onClick ?? (props.navigatesTo ? () => {
        navigate(props.navigatesTo ?? "/");
    } : undefined);

    return (
        <button
            className={"action-button"}
            type={(props.submits ?? false) ? "submit" : "button"}
            onClick={onClick}
        >
            {props.label}
        </button>
    );
}

export default ActionButton;