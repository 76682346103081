import { useState } from 'react';
import ActionButton from '../../atoms/ActionButton/ActionButton';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import './FriendRequest.scss';
import chatService from '../../../services/chatService';

interface FriendRequestProps {
    id: number;
    userName: string;
    fullName: string;
    friendAccepted?: () => void;
}

const FriendRequest = (props: FriendRequestProps) => {

    const [accepted, setAccepted] = useState(false);

    const [rejected, setRejected] = useState(false);

    const [error, setError] = useState('');

    const handleAcceptButtonClick = async () => {
        await chatService
            .acceptFriendRequest(props.id)
            .then(data => {
                setAccepted(true);
            })
            .catch(error => {
                setError(error.response?.data?.error ?? 'Error occurred.');
            });
        if (props.friendAccepted) {
            props.friendAccepted();
        }
    }

    const handleDeclineButtonClick = async () => {
        await chatService
            .rejectFriendRequest(props.id)
            .then(data => {
                setRejected(true);
            })
            .catch(error => {
                setError(error.response?.data?.error ?? 'Error occurred.');
            });
    }

    return (
        <div className="friend-request-balloon">
            <div className="friend-balloon-header">
                <span className="friend-request-balloon-name">{props.fullName}</span>
                {accepted && <span> - Accepted</span>}
                {rejected && <span> - Rejected</span>}
            </div>
            {error && <ErrorAlert title='Cannot process response' message={error} />}
            <div className="friend-request-balloon-buttons">
                {!accepted && !rejected &&
                    <>
                        <ActionButton label='Accept' onClick={() => handleAcceptButtonClick()} />
                        <ActionButton label='Decline' onClick={() => handleDeclineButtonClick()} />
                    </>
                }
            </div>
        </div >
    );
}

export default FriendRequest;