import { useState } from 'react';
import './ToggleSwitch.scss';

interface ToggleSwitchProps {
    label?: string;
    checked?: boolean;
    onChange: (value: boolean) => void;
}

const ToggleSwitch = (props: ToggleSwitchProps) => {

    const [checked, setChecked] = useState(props.checked ?? false);

    const handleCheckChanged = (checked: boolean) => {
        setChecked(checked);
        props.onChange(checked);
    }

    return (
        <div className="switch-container">
            <label className="switch">
                <input type="checkbox" checked={checked} onChange={e => handleCheckChanged(e.target.checked)} />
                <span className="slider"></span>
            </label>
            {props.label && <span className="switch-label">{props.label}</span>}
        </div>
    );
}

export default ToggleSwitch;