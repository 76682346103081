import './StatusBar.scss';
import { useContext } from "react";
import GlobalVariablesContext from "../../../services/GlobalVariablesContext";

const StatusBar = () => {

    const { webSocketConnected } = useContext(GlobalVariablesContext);

    const className = webSocketConnected ? 'status-bar-online' : 'status-bar-offline';

    return (
        <div id="status-bar" className={className}>
            <span>{webSocketConnected ? 'Online' : 'Offline'}</span>
        </div>
    );

}

export default StatusBar;