import './FriendBalloon.scss';
import { useState } from 'react';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import ActionButton from '../../atoms/ActionButton/ActionButton';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import chatService from '../../../services/chatService';

interface FriendBalloonProps {
    id: number;
    userName: string;
    fullName: string;
    onUnfriend?: () => void
}

const FriendBalloon = (props: FriendBalloonProps) => {

    const [error, setError] = useState('');

    const [confirmUnfriend, setConfirmUnfriend] = useState(false);

    const handleUnfriendButtonClick = () => {
        setConfirmUnfriend(true);
    }

    const handleConfirmUnfriend = async () => {
        await chatService
            .removeFriend(props.id)
            .then(data => {
                if (props.onUnfriend) {
                    props.onUnfriend();
                }
            })
            .catch(error => {
                setError(error.response?.data?.error ?? 'Error occurred.');
            });
    }

    return (
        <>
            <div className="friend-balloon">
                <div className="friend-balloon-header">
                    <span className="friend-balloon-name">{props.fullName}</span>
                </div>
                <div className="friend-balloon-buttons">
                    <ActionButton label='Unfriend' onClick={() => handleUnfriendButtonClick()} />
                </div>
            </div>
            {confirmUnfriend && <ConfirmDialog open={confirmUnfriend} title="Unfriend" message={'Do you really want to unfriend ' + props.fullName + '?'} onYesClick={() => handleConfirmUnfriend()} onNoClick={() => setConfirmUnfriend(false)} />}
            {error && <ErrorAlert title='Error' message={error} />}
        </>
    );
}

export default FriendBalloon;