import "./ErrorAlert.scss"

interface ErrorAlertProps {
    title: string;
    message: string;
}

const ErrorAlert = (props: ErrorAlertProps) => {
    return (
        <div className="message-error">
            <p className="message-error-title">{props.title}</p>
            <p className="message-error-message">{props.message}</p>
        </div>
    );
}

export default ErrorAlert;