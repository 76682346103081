import './FriendSearchPage.scss';
import { useState } from 'react';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import TextBox from '../../atoms/TextBox/TextBox';
import LinkBox from '../../atoms/LinkBox/LinkBox';
import WideButton from '../../atoms/WideButton/WideButton';
import FriendSearchResult from '../../organisms/FriendSearchResult/FriendSearchResult';
import BackLink from '../../atoms/BackLink/BackLink';
import { useNavigate } from 'react-router-dom';
import chatService, { IUserData } from '../../../services/chatService';

const FriendSearchPage = () => {

    const [term, setTerm] = useState('');

    const [friends, setFriends] = useState<IUserData[]>([]);

    const [friendAdded, setFriendAdded] = useState(false);

    const [error, setError] = useState('');

    const handleSearchButtonClick = async () => {
        await chatService
            .searchForUsers(term)
            .then(data => {
                setError('');
                setFriends(data.data);
                if (data.data.length === 0) {
                    setError('No friends found matching the specified text!');
                }
            })
            .catch(error => {
                setError(error.response?.data?.error ?? 'Error occurred.');
            });
    }

    const handleReloadButtonClick = () => {
        setFriends([]);
        setError('');
    }

    const handleRequestSent = () => {
        setFriendAdded(true);
    }

    const navigate = useNavigate();

    const handleSearchAgainButtonClick = () => {
        setFriendAdded(false);
        setTerm('');
        setFriends([]);
    }

    const handleGoHomeButtonClick = () => {
        navigate('/home');
    }

    return (
        <div id="main-content" className="friend-search-page-container">
            {friends.length > 0 && <BackLink onClick={() => handleReloadButtonClick()} label='Find Friends' />}

            <h1>Find Friend</h1>

            {friends.length === 0 && <>
                <h2>Your friend's first or last name:</h2>
                <TextBox name="term" placeholder="Enter Name" required={true} onChange={e => setTerm(e.target.value)} />
                <WideButton label='Search' onClick={() => handleSearchButtonClick()} />
            </>}

            {error !== '' && <ErrorAlert title='Cannot find user' message={error} />}

            {friends.map(f =>
                <FriendSearchResult key={f.id} userName={f.userName} fullName={f.fullName} requestSent={() => handleRequestSent()} />
            )}

            {friendAdded &&
                <>
                    <WideButton label='Search for another friend' onClick={() => handleSearchAgainButtonClick()} />
                    <WideButton label='Return home' onClick={() => handleGoHomeButtonClick()} />
                </>
            }
            {!friendAdded && <LinkBox href="/home" label="Cancel" />}
        </div>
    );
}

export default FriendSearchPage;