import './ChatUserTags.scss';
import { IChatGroupUser } from "../../../services/chatService";

interface ChatUserTagsProps {
    users: IChatGroupUser[];
}

const ChatUserTags = (props: ChatUserTagsProps) => {
    return (
        <div className="chat-users">
            {props.users.map(u => (
                <span key={u.userName} className="chat-user">{u.fullName}</span>
            ))}
        </div>
    );
}

export default ChatUserTags;