import { useState } from 'react';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import WideButton from '../../atoms/WideButton/WideButton';
import './FriendSearchResult.scss';
import chatService from '../../../services/chatService';

interface FriendSearchResultProps {
    userName: string;
    fullName: string;
    requestSent: () => void;
}

const FriendSearchResult = (props: FriendSearchResultProps) => {

    const [madeRequest, setMadeRequest] = useState(false);

    const [error, setError] = useState('');

    const sendFriendRequest = async (userName: string) => {
        await chatService
            .createFriendRequest({ userName: userName })
            .then(data => {
                setError('');
                setMadeRequest(true);
                props.requestSent();
            })
            .catch(error => {
                setError(error.response?.data?.error ?? 'Error occurred.');
            });
    }

    return (
        <div className="found-friend">
            <p className="found-friend-name">{props.fullName}</p>

            {madeRequest
                ? <p className="found-friend-requested">You have sent a friend request to {props.fullName}!</p>
                : <div className="found-friend-buttons">
                    <WideButton label='Send Friend Request' onClick={() => sendFriendRequest(props.userName)} />
                </div>
            }

            {error !== '' && <ErrorAlert title='Cannot Send Friend Request' message={error} />}
        </div>
    );
}

export default FriendSearchResult;