import config from "../config.json";
import axios, { AxiosInstance, AxiosResponse } from "axios";

export interface IRegisterData {
    deviceToken: string;
}

export interface ICreateFriendRequestData {
    userName: string;
}

export interface IUserData {
    id: number;
    userName: string;
    fullName: string;
}

export interface IFriendRequestsData {
    forMe: IUserData[];
    forOthers: IUserData[];
}

export interface IChatGroupSummaryInfo {
    id: number;
    ownerUserName: string;
    ownerFullName: string;
    name: string;
    title: string;
    createDate: string;
    lastMessageAuthor: string;
    lastMessageContent: string;
    lastMessageDate: string;
    unreadCount: number;
    users: IChatGroupUser[];
}

export interface IChatGroupUser {
    userName: string;
    fullName: string;
    isAdmin: boolean;
}

export interface IChatGroupInfo extends IChatGroupSummaryInfo {
    messages: IChatMessage[];
}

export interface IChatMessage {
    id: number;
    userName: string;
    fullName: string;
    message: string;
    authoredDate: string;
}

export interface ICreateGroupRequest {
    title: string;
    friends: string[];
}

export interface ICreateGroupResponse {
    name: string;
    title: string;
}

export interface ICreateMessageRequest {
    groupName: string;
    message: string;
}

export interface IRemoveGroupUserRequest {
    groupName: string;
    userName: string;
}

export interface ICreateMessageResponse {
    id: number;
    userId: number;
    userName: string;
    fullName: string;
}

export interface IErrorResponse {
    statusCode: number;
    message: string;
}

export interface IRegisterResponse {
    id: number;
}

class ChatService {

    private axios: AxiosInstance;

    public readonly baseUrl: string;

    private bearerToken: string = null;

    public loggedIn: boolean = false;

    constructor() {
        this.baseUrl = config.chatApiBaseUrl;
        this.axios = axios.create({ baseURL: config.chatApiBaseUrl });
        this.bearerToken = localStorage.getItem('mousechat.bearerToken');

        this.axios.interceptors.response.use(response => {
            return response;
        }, (error) => {
            const status = error.response?.status;
            if (status === 401) {
                window.location.href = config.b2cSignInUrl;
            } else {
                return Promise.reject(error);
            }
        });
    }

    async register(data: IRegisterData): Promise<AxiosResponse<IRegisterResponse>> {
        this.bearerToken = localStorage.getItem('mousechat.bearerToken');
        return await this.axios.post('register', data, this.getRequestConfig());
    }

    async logout() {
        this.bearerToken = null;
        this.loggedIn = false;
        localStorage.removeItem('mousechat.bearerToken');
    }

    async searchForUsers(term: string): Promise<AxiosResponse<IUserData[]>> {
        return await this.axios.post('search-users', { term: term }, this.getRequestConfig());
    }

    async createFriendRequest(data: ICreateFriendRequestData) {
        return await this.axios.post('friend-requests', data, this.getRequestConfig());
    }

    async getFriendRequests(): Promise<AxiosResponse<IFriendRequestsData>> {
        return await this.axios.get('friend-requests', this.getRequestConfig());
    }

    async cancelFriendRequest(id: number) {
        return await this.axios.post('friend-requests/' + encodeURIComponent(id) + '/cancel', null, this.getRequestConfig());
    }

    async acceptFriendRequest(id: number) {
        return await this.axios.post('friend-requests/' + encodeURIComponent(id) + '/accept', null, this.getRequestConfig());
    }

    async rejectFriendRequest(id: number) {
        return await this.axios.post('friend-requests/' + encodeURIComponent(id) + '/reject', null, this.getRequestConfig());
    }

    async getFriends(): Promise<AxiosResponse<IUserData[]>> {
        return await this.axios.get('friends', this.getRequestConfig());
    }

    async removeFriend(id: number) {
        return await this.axios.delete('friends/' + encodeURIComponent(id), this.getRequestConfig());
    }

    async createGroup(data: ICreateGroupRequest): Promise<AxiosResponse<ICreateGroupResponse>> {
        return await this.axios.post('groups', data, this.getRequestConfig());
    }

    async getGroups(): Promise<AxiosResponse<IChatGroupSummaryInfo[]>> {
        return await this.axios.get('groups', this.getRequestConfig());
    }

    async getGroup(groupName: string): Promise<AxiosResponse<IChatGroupInfo>> {
        return await this.axios.get(`groups/${groupName}`, this.getRequestConfig());
    }

    async createMessage(data: ICreateMessageRequest): Promise<AxiosResponse<ICreateMessageResponse>> {
        return await this.axios.post(`groups/${data.groupName}/messages`, data, this.getRequestConfig());
    }

    async getMessages(groupName: string, since: number = -1): Promise<AxiosResponse<IChatMessage[]>> {
        return await this.axios.get(`groups/${groupName}/messages?since=${since}`, this.getRequestConfig());
    }

    async removeGroupUser(data: IRemoveGroupUserRequest): Promise<AxiosResponse<ICreateMessageResponse>> {
        return await this.axios.delete(`groups/${data.groupName}/users/${data.userName}`, this.getRequestConfig());
    }

    private getRequestConfig() {
        return {
            headers: {
                'Authorization': `Bearer ${this.bearerToken}`
            }
        }
    }

}

const chatService = new ChatService();

export default chatService;
