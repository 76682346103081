import { useState } from "react";
import { IUserData } from "../../../services/chatService";
import Spinner from "../../atoms/Spinner/Spinner";
import FriendToggle from "../FriendToggle/FriendToggle";

interface FriendListSelectorProps {
    friends: IUserData[];
    selectedFriends: string[];
    onChange: (friends: string[]) => void;
}

const FriendListSelector = (props: FriendListSelectorProps) => {

    const [selectedFriends, setSelectedFriends] = useState<string[]>([]);

    const handleFriendToggled = (userName: string, on: boolean) => {
        if (on) {
            let newSelectedFriends = [...selectedFriends, userName];
            setSelectedFriends(newSelectedFriends);
            props.onChange(newSelectedFriends);
        } else {
            let newSelectedFriends = selectedFriends.filter(f => f !== userName);
            setSelectedFriends(newSelectedFriends);
            props.onChange(newSelectedFriends);
        }
    }

    return (
        <>
            {props.friends === null
                ? <Spinner />
                : props.friends.map(f =>
                    <FriendToggle key={f.id} id={f.id} userName={f.userName} fullName={f.fullName} checked={selectedFriends.indexOf(f.userName) !== -1} onChange={checked => handleFriendToggled(f.userName, checked)} />
                )
            }
        </>
    );
}

export default FriendListSelector;