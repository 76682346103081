import './FriendsPage.scss';
import { useContext, useEffect, useState } from 'react';
import chatService, { IFriendRequestsData, IUserData } from '../../../services/chatService';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import WideButton from '../../atoms/WideButton/WideButton';
import Spinner from '../../atoms/Spinner/Spinner';
import FriendRequest from '../../organisms/FriendRequest/FriendRequest';
import FriendBalloon from '../../organisms/FriendBalloon/FriendBalloon';
import FriendRequestPendingBalloon from '../../organisms/FriendRequestPendingBalloon/FriendRequestPendingBalloon';
import GlobalVariablesContext from '../../../services/GlobalVariablesContext';

const FriendsPage = () => {

    const [loadingFriends, setLoadingFriends] = useState(true);

    const [friends, setFriends] = useState<IUserData[]>(null);

    const [friendsError, setFriendsError] = useState('');

    const [loadingFriendRequests, setLoadingFriendRequests] = useState(true);

    const [friendRequests, setFriendRequests] = useState<IFriendRequestsData>(null);

    const [friendRequestsError, setFriendRequestsError] = useState('');

    const { setActiveGroup } = useContext(GlobalVariablesContext);

    useEffect(() => {
        setActiveGroup(null);
    }, []);

    const loadFriends = async () => {
        setLoadingFriends(true);
        setFriends(null);
        await chatService
            .getFriends()
            .then(data => {
                setLoadingFriends(false);
                setFriendsError('');
                setFriends(data.data);
            })
            .catch(error => {
                setLoadingFriends(false);
                setFriendsError(error.response?.data?.error ?? 'Error occurred.');
            });
    }

    const loadPendingFriendRequests = async () => {
        setLoadingFriendRequests(true);
        setFriendRequests(null);
        await chatService
            .getFriendRequests()
            .then(data => {
                setLoadingFriendRequests(false);
                setFriendRequestsError('');
                setFriendRequests(data.data);
            })
            .catch(error => {
                setLoadingFriendRequests(false);
                setFriendRequestsError(error.response?.data?.error ?? 'Error occurred.');
            });
    }

    useEffect(() => {
        loadFriends();
        loadPendingFriendRequests();
    }, []);

    const handleCancelFriendRequest = async (id: number) => {
        loadPendingFriendRequests();
    }

    const handleUnfriended = () => {
        loadFriends();
    }

    const handleFriendAccepted = () => {
        loadFriends();
    }

    return (
        <div id="main-content" className="friends-page-container">

            {(loadingFriends || loadingFriendRequests) && <Spinner />}

            <h1>Friends</h1>

            <WideButton label='Add Friend' navigatesTo='/friend-search' />

            {friends?.length === 0 && <p>You do not have any friends!</p>}

            {friends?.map(f =>
                <FriendBalloon key={f.id} id={f.id} userName={f.userName} fullName={f.fullName} onUnfriend={() => handleUnfriended()} />
            )}

            {friendsError && <ErrorAlert title='Cannot load friends' message={friendsError} />}

            {(friendRequests?.forMe?.length > 0 || friendRequests?.forOthers?.length > 0) && <h1>Friend Requests</h1>}



            {friendRequests?.forMe?.map(f =>
                <FriendRequest key={f.id} id={f.id} userName={f.userName} fullName={f.fullName} friendAccepted={() => handleFriendAccepted()} />
            )}

            {friendRequests?.forOthers?.map(f =>
                <FriendRequestPendingBalloon key={f.id} id={f.id} userName={f.userName} fullName={f.fullName} onUnrequest={() => () => handleCancelFriendRequest(f.id)} />
            )}

            {friendRequestsError && <ErrorAlert title='Cannot load friend requests' message={friendRequestsError} />}

        </div>
    );
}

export default FriendsPage;