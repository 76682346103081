import './SignedInPage.scss';
import { useEffect, useState } from 'react';
import chatService from '../../../services/chatService';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import LinkBox from '../../atoms/LinkBox/LinkBox';
import MainLogo from '../../atoms/MainLogo/MainLogo';

const SignedInPage = () => {

    const [error, setError] = useState(null);

    useEffect(() => {
        setTimeout(() => {

            let deviceToken = localStorage.getItem('mousechat.deviceToken');
            if (deviceToken) {
                localStorage.removeItem(deviceToken);
            }

            let info = new URLSearchParams(window.location.hash.substring(1));
            let rawToken = info.get('id_token');
            let errorDescription = info.get('error_description');
            if (rawToken) {
                let base64Url = rawToken.split('.')[1];
                let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                let tokenJson = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                localStorage.setItem('mousechat.bearerToken', rawToken);
                localStorage.setItem('mousechat.user', tokenJson);

                chatService.register({ deviceToken: deviceToken }).then(() => {
                    window.location.href = 'home';
                })

            } else if (errorDescription) {
                setError(errorDescription);
            } else {
                setError('The token is invalid.');
            }

        }, 100);
    }, []);

    return (
        <>
            {error && <ErrorAlert title='Could not sign in' message={error} />}

            {!error &&
                <div className="app-icon">
                    <MainLogo />
                </div>
            }
        </>
    );
}

export default SignedInPage;