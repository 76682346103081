import { Link } from 'react-router-dom';
import './LinkBox.scss';

interface LinkBoxProps {
    label: string;
    href: string;
}

const LinkBox = (props: LinkBoxProps) => {
    return (
        <div className="link-box">
            <span><Link to={props.href}>{props.label}</Link></span>
        </div>
    );
}

export default LinkBox;