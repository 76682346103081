import './GroupsList.scss';
import { useContext } from "react";
import { IChatGroupSummaryInfo } from "../../../services/chatService";
import GroupBar from "../GroupBar/GroupBar";
import GlobalVariablesContext from "../../../services/GlobalVariablesContext";

interface GroupsListProps {
    groups: IChatGroupSummaryInfo[];
    onGroupClick: (group: IChatGroupSummaryInfo) => void;
}

const GroupsList = (props: GroupsListProps) => {

    const handleGroupClick = (group: IChatGroupSummaryInfo) => {
        props.onGroupClick(group);
    };

    const { activeGroup } = useContext(GlobalVariablesContext);

    return (
        props.groups && <>
            <div id="groups-list">
                {props.groups.map(g => (
                    <GroupBar key={g.id} group={g} active={g.name === activeGroup?.name} onClick={() => handleGroupClick(g)} />
                ))}
            </div>
        </>
    );
}

export default GroupsList;