import './ChatMessage.scss';
import useClaims from "../../../hooks/useClaims";
import { IChatMessage } from "../../../services/chatService";

interface IChatMessage2Props {
    message: IChatMessage;
}

const ChatMessage = (props: IChatMessage2Props) => {

    const claims = useClaims();

    const isToday = (someDate: Date) => {
        const today = new Date();
        return someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()
    }

    let date = new Date(props.message.authoredDate);
    let dateString = isToday(date) ? date.toLocaleTimeString() : date.toLocaleDateString() + ' ' + date.toLocaleTimeString();

    let className = 'chat-message';
    if (claims.sub === props.message.userName) {
        className += ' chat-message-highlight';
    }

    return (
        <div className={className}>
            <span className="chat-message-meta">
                {props.message.fullName} {dateString}
            </span>
            <span className="chat-message-content">
                {props.message.message}
            </span>
        </div>
    );
}

export default ChatMessage;