import './CreateGroupPage.scss';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import TextBox from '../../atoms/TextBox/TextBox';
import LinkBox from '../../atoms/LinkBox/LinkBox';
import WideButton from '../../atoms/WideButton/WideButton';
import chatService, { IUserData } from '../../../services/chatService';
import FriendListSelector from '../../organisms/FriendListSelector/FriendListSelector';
import GlobalVariablesContext from '../../../services/GlobalVariablesContext';

const CreateGroupPage = () => {

    const [groupTitle, setGroupTitle] = useState('');

    const [friends, setFriends] = useState<IUserData[]>(null);

    const [selectedFriends, setSelectedFriends] = useState<string[]>([]);

    const [error, setError] = useState('');

    const { setActiveGroup } = useContext(GlobalVariablesContext);

    useEffect(() => {
        setActiveGroup(null);
    }, []);
    
    let navigate = useNavigate();

    const loadFriends = async () => {
        setFriends(null);
        await chatService
            .getFriends()
            .then(data => {
                setFriends(data.data);
                setSelectedFriends([]);
            })
            .catch(error => {
                setError(error.response?.data?.error ?? 'Error occurred.');
            });
    }

    useEffect(() => {
        loadFriends();
    }, []);

    const handleCreateGroupButtonClick = async () => {
        await chatService
            .createGroup({
                title: groupTitle,
                friends: selectedFriends
            })
            .then(data => {
                navigate('/groups/' + encodeURIComponent(data.data.name));
            })
            .catch(error => {
                setError(error.response?.data?.error ?? 'Error occurred.');
            });
    }

    return (
        <div id="main-content" className="create-group-page-container">
            <h1>Create Group</h1>

            <form method="post">

                <h2>Group name:</h2>
                <TextBox name="groupTitle" placeholder="Enter group name" required={true} onChange={e => setGroupTitle(e.target.value)} />

                <h2>Friends in the group:</h2>
                <FriendListSelector friends={friends} selectedFriends={selectedFriends} onChange={friends => setSelectedFriends(friends)} />

                {error !== '' && <ErrorAlert title='Error creating group' message={error} />}

                <WideButton label={'Create'} enabled={(groupTitle ?? '') !== '' && selectedFriends?.length > 0} onClick={() => handleCreateGroupButtonClick()} />

            </form>

            <LinkBox href="/home" label="Cancel" />
        </div>
    );
}

export default CreateGroupPage;