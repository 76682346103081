import './FriendRequestPendingBalloon.scss';
import { useState } from 'react';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import ActionButton from '../../atoms/ActionButton/ActionButton';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import chatService from '../../../services/chatService';

interface FriendRequestPendingBalloonProps {
    id: number;
    userName: string;
    fullName: string;
    onUnrequest?: () => void
}

const FriendRequestPendingBalloon = (props: FriendRequestPendingBalloonProps) => {

    const [error, setError] = useState('');

    const [confirmUnrequest, setConfirmUnrequest] = useState(false);

    const handleUnrequestButtonClick = () => {
        setConfirmUnrequest(true);
    }

    const handleConfirmUnrequest = async () => {
        await chatService
            .cancelFriendRequest(props.id)
            .then(data => {
                if (props.onUnrequest) {
                    props.onUnrequest();
                }
            })
            .catch(error => {
                setError(error.response?.data?.error ?? 'Error occurred.');
            });
    }

    return (
        <>
            <div className="friend-request-pending-balloon">
                <div className="friend-request-pending-balloon-header">
                    <span className="friend-request-pending-balloon-name">Waiting for: {props.fullName}</span>
                </div>
                <div className="friend-request-pending-balloon-buttons">
                    <ActionButton label='Cancel' onClick={() => handleUnrequestButtonClick()} />
                </div>
            </div>
            {confirmUnrequest && <ConfirmDialog open={confirmUnrequest} title="Cancel Friend Request" message={'Do you really want to cancel the friend request to ' + props.fullName + '?'} onYesClick={() => handleConfirmUnrequest()} onNoClick={() => setConfirmUnrequest(false)} />}
            {error && <ErrorAlert title='Error' message={error} />}
        </>
    );
}

export default FriendRequestPendingBalloon;