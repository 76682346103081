import './GroupsPage.scss';
import { useContext, useEffect, useState } from 'react';
import chatService, { IChatGroupSummaryInfo } from '../../../services/chatService';
import ErrorAlert from '../../atoms/ErrorAlert/ErrorAlert';
import Spinner from '../../atoms/Spinner/Spinner';
import GlobalVariablesContext from '../../../services/GlobalVariablesContext';
import GroupBar from '../../organisms/GroupBar/GroupBar';
import { useNavigate } from 'react-router-dom';

const GroupsPage = () => {

    const [loadingGroups, setLoadingGroups] = useState(true);

    const [groupsError, setGroupsError] = useState('');

    const { allGroups, setAllGroups, activeGroup } = useContext(GlobalVariablesContext);

    const navigate = useNavigate();

    const loadGroups = async () => {
        setLoadingGroups(true);
        setAllGroups(null);
        await chatService
            .getGroups()
            .then(data => {
                setLoadingGroups(false);
                setGroupsError('');
                setAllGroups(data.data);
            })
            .catch(error => {
                setLoadingGroups(false);
                setGroupsError(error.response?.data?.error ?? 'Error occurred.');
            });
    }

    useEffect(() => {
        loadGroups();
    }, []);

    const handleGroupClick = (group: IChatGroupSummaryInfo) => {
        navigate('/groups/' + group.name);
    };

    return (
        <div id="main-content" className="groups-page-container">

            {loadingGroups && <Spinner />}

            {allGroups?.length === 0 && <p>You have not yet created a group!</p>}

            {allGroups && <div id="mobile-groups-list">
                {allGroups?.map(g =>
                    <GroupBar key={g.id} active={g.id === activeGroup?.id} group={g} onClick={() => handleGroupClick(g)} />
                )}
            </div>}

            {groupsError && <ErrorAlert title='Cannot load groups' message={groupsError} />}
        </div>
    );
}

export default GroupsPage;