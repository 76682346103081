import './GroupBar.scss';
import { useState } from "react";
import { IChatGroupSummaryInfo } from "../../../services/chatService";

interface GroupBarProps {
    group: IChatGroupSummaryInfo;
    active: boolean;
    onClick: () => void;
}

const GroupBar = (props: GroupBarProps) => {

    const [unreadCount, setUnreadCount] = useState(props.group.unreadCount);

    const handleClick = () => {
        setUnreadCount(0);
        props.onClick();
    }

    let className = 'group-bar';
    if (props.active) {
        className += ' group-bar-highlight';
    }

    return (
        <div className={className} onClick={handleClick}>
            <span className="group-bar-name">{props.group.title}</span>
            {props.group.lastMessageAuthor && <span className="group-bar-details">{props.group.lastMessageAuthor}: {props.group.lastMessageContent}</span>}
            {unreadCount > 0 && <span className="group-bar-unread">{unreadCount}</span>}
        </div>
    );
}

export default GroupBar;